import BookIcon from 'src/assets/icons/driving-guidelines.svg';
import ChartIcon from 'src/assets/icons/account.svg';
import HeadIcon from 'src/assets/icons/depression.svg';

const content = {
  title: 'EKS - Grundfähigkeitsversicherung',
  headvisualHeadline:
    'Betrieblicher Einkommensschutz (EKS) - Grundfähigkeitsversicherung',
  faehigkeitenSection: {
    headline: 'Weil es auf die Fähigkeiten ankommt',
    content:
      'In ihrem Beruf und Alltag brauchen Ihre Mitarbeiter ganz bestimmte Fähigkeiten. Ob Sitzen, Gehen, die Arme gebrauchen, Sehen oder Sprechen:  Das alles ist für uns selbstverständlich. Was passiert aber, wenn man nur eine dieser Grundfähigkeiten verliert?  Vom Staat bekommt man wenig bis gar keine Unterstützung.'
  },
  loesungSection: {
    headline: 'Die Lösung',
    background: 'light-salmon',
    content:
      'Mit der Grundfähigkeitsversicherung sorgen Sie dafür, dass körperliche und geistige Einschränkungen Ihrer Mitarbeiter nicht zu finanziellen werden. Denn die monatliche Rente schützt die Existenz Ihrer Mitarbeiter und hilft dabei das Leben neu zu organisieren.'
  },
  grundfaehigkeitenSection: {
    headline: 'Die versicherten Grundfähigkeiten',
    paragraphs: [
      'Die Grundfähigkeitsversicherung bietet Schutz in Form einer monatlichen Rente bei Verlust einer der 18 (Leistungspaket Comfort) bzw. 19 (Leistungspaket Premium) versicherten Grundfähigkeiten. Die Möglichkeit weitere optionale Bausteine einzuschließen runden die Grundfähigkeitsversicherung ab.',
      'Welche Grundfähigkeiten in den Paketen versichert sind entnehmen Sie der nebenstehenden Grafik.'
    ]
  },
  individualitätsSection: {
    headline: 'Individualität - wie es Ihre Kunden brauchen',
    background: 'light-blue',
    content:
      'Die NÜRNBERGER bietet Ihnen mit der Grundfähigkeitsversicherung in der bAV die Möglichkeit, die Einkommensabsicherung Ihrer Mitarbeiter individuell zu gestalten. Für einen bedarfsgerechten Versicherungsschutz stehen Ihren Mitarbeitern neben zwei Tarifen vielfältige Zusatzbausteine zur Verfügung.',
    columns: [
      {
        headline: 'Leistungspakete:',
        items: ['Premium (6 Monate) oder', 'Comfort (12 Monate)']
      },
      {
        headline: 'Zusatzbausteine:',
        items: [
          'Psychische Erkrankung',
          'LKW- / Busfahren (Fahrlizenzverlust Klasse C oder D)',
          'Inflations-Schutz'
        ]
      }
    ]
  },
  zusatzbausteineSection: {
    headline:
      'Gestalten Sie den Schutz Ihrer Mitarbeiter einfach individuell mit optionalen Zusatzbausteinen:',
    headerColor: 'dark-blue',
    cards: [
      {
        icon: HeadIcon,
        headline: 'Psychischer Erkrankung',
        content:
          'Ihr Mitarbeiter erhält eine Leistung bei Erwerbsminderung infolge einer psychischen Erkrankung.'
      },
      {
        icon: BookIcon,
        headline: 'Fahrlizensverlust (Klasse C/D) Lkw-/Busfahrer',
        content:
          'Ihr Mitarbeiter erhält eine Leistung wenn die Fahrlizenz der Klasse C oder D aus gesundheit-gesundheitlichen Gründen entzogen oder nicht verlängert wird (nur im Premium-Tarif).'
      },
      {
        icon: ChartIcon,
        headline: 'Inflations-Schutz',
        content:
          'Ihr Mitarbeiter erhält eine garantierte Rentensteigerung im Leistungsfall'
      }
    ]
  },
  vorteileSection: {
    headline: 'Ihre Vorteile als Arbeitgeber',
    background: 'light-blue',
    items: [
      'Sie steigern Ihre Attraktivität als Arbeitgeber – und haben bessere Chancen bei der Suche nach Fachkräften',
      'Die Ausgaben können Sie komplett als Betriebsausgaben absetzen',
      'Sie erhöhen die Loyalität Ihrer Mitarbeiter mit Ihrem Unternehmen und senken die Fluktuation',
      'Mit unseren bAV-Portalen verwalten Sie die Verträge online – ohne großen Aufwand',
      'Sie zeigen soziale Verantwortung & Wertschätzung gegenüber Ihren Arbeitnehmern'
    ]
  }
};

export default content;
