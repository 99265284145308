import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  List,
  Grid,
  NonStretchedImage,
  Paragraphs
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-grundfaehigkeitsversicherung';
import subpageNavigations from 'src/data/subpage-navigations';

const EKSGrundfaehigkeitsversicherungPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.betrieblicherEinkommensschutz}
    activeNavItem={2}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.faehigkeitenSection.headline} centered>
        <p>{content.faehigkeitenSection.content}</p>
      </SectionHead>
      <HalvedContent align="center">
        <BackgroundImage
          fluid={data.womanStandingInOffice.childImageSharp.fluid}
        />
        <ContentBox
          background={content.loesungSection.background}
          headline={content.loesungSection.headline}
        >
          <p>{content.loesungSection.content}</p>
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <Grid centerItems>
        <div className="children-margin">
          <h3>{content.grundfaehigkeitenSection.headline}</h3>
          <Paragraphs items={content.grundfaehigkeitenSection.paragraphs} />
        </div>
        <NonStretchedImage
          fluid={data.versichertenIllu.childImageSharp.fluid}
          className="max-w-[560px] max-h-[681px]"
        />
      </Grid>
    </section>
    <section className="bg-light-blue">
      <Grid centerItems>
        <NonStretchedImage
          fluid={data.grundfaehigkeitenIllu.childImageSharp.fluid}
        />
        <div
          className={`children-margin bg-${content.individualitätsSection.background}`}
        >
          <h3>{content.individualitätsSection.headline}</h3>
          <p>{content.individualitätsSection.content}</p>
          <div className="flex">
            {content.individualitätsSection.columns.map((column, index) => (
              <div key={index}>
                <h5>{column.headline}</h5>
                <List items={column.items} />
              </div>
            ))}
          </div>
        </div>
      </Grid>
    </section>
    <section>
      <SectionHead
        headline={content.zusatzbausteineSection.headline}
        centered
      />
      <Grid>
        {content.zusatzbausteineSection.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader
              headline={card.headline}
              color={content.zusatzbausteineSection.headerColor}
            >
              <card.icon />
            </CardHeader>
            <p>{card.content}</p>
          </Card>
        ))}
      </Grid>
    </section>
    <BackgroundImage
      fluid={data.emptyOffice.childImageSharp.fluid}
      className="full-width-image bg-bottom"
    />
    <section>
      <ContentBox
        headline={content.vorteileSection.headline}
        background={content.vorteileSection.background}
        single
        shifted
      >
        <List items={content.vorteileSection.items} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default EKSGrundfaehigkeitsversicherungPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-dark-long-hair.jpg" }
    ) {
      ...image
    }
    womanStandingInOffice: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-standing-in-office.jpg" }
    ) {
      ...image
    }
    versichertenIllu: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "ngf-faehigkeiten.png" }
    ) {
      ...image
    }
    grundfaehigkeitenIllu: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "ngf-bausteine.jpg" }
    ) {
      ...image
    }
    emptyOffice: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "empty-office.jpg" }
    ) {
      ...image
    }
  }
`;
